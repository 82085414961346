import React, { useState } from 'react';
import { MdKeyboardArrowDown } from 'react-icons/md';
import { useField, Field } from 'formik';

/**
 *
 * data: [{
 *  value: string;
 *  label: string;
 * }]
 *
 * label: string
 * id: string
 * name: string
 * placeholder: string
 * default: string
 */
const DropdownSelect = (props) => {
  const { label, ...rest } = props;
  const [showOpts, setShowOpts] = useState(false);
  const [field, meta, helpers] = useField(rest);

  const { setValue } = helpers;

  return (
    <div className='relative'>
      {props.label && (
        <label
          htmlFor={props.name || props.id}
          className='block mb-5 text-base tracking-wide '>
          {props.label}
        </label>
      )}

      <div
        className='relative w-full  '
        onClick={() => {
          setShowOpts((s) => !s);
        }}>
        <Field
          className={`hover:cursor-pointer block w-full py-4 border bg-bggrey dark:bg-wdark-400 text-[#9fa19c] text-sm rounded-2xl px-3 pr-6 focus:outline-none dark:border-wdark-300  focus:border-afexgreen appearance-none invalid:ring-red-400 ${
            props.className
          } ${showOpts && ' border-afexgreen dark:border-afexgreen'}`}
          disabled={props.disabled}
          id={props.id}
          name={props.name}
          // value={select.value}
          {...field}
          {...rest}
        />
        <MdKeyboardArrowDown className='absolute top-[30%] right-3 text-gray-400 text-lg' />{' '}
        <ul
          className={`overflow-auto absolute top-[105%] left-0 rounded-2xl  z-10 px-2 transition-[max-height] child:p-1 hover:child:bg-afexgreen-lighter child:cursor-pointer child:m-1 bg-bggrey dark:bg-wdark-400 w-full ring-1 ring-afexgreen ${
            showOpts ? 'max-h-96 opacity-100' : 'max-h-0 opacity-0'
          }`}
          onClick={(e) => e.stopPropagation()}>
          {props.data.map((option, index) => (
            <li
              key={index}
              onClick={() => {
                setValue(option.value);
                setShowOpts(false);
              }}
              className='text-gray-400 rounded-lg'>
              {String(option.label)}
            </li>
          ))}
        </ul>
      </div>

      {meta.error && meta.touched && (
        <span className='block text-red-400 text-xs pt-2'>{meta.error} </span>
      )}
    </div>
  );
};

export default DropdownSelect;

