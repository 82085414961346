import React from 'react';
import TransactionDropdown from './dropdown';

import {
  commaFormatter,
  decimalFormatter,
} from '../../../../../utils/formatter';

const TrasactionSummary = ({
  transaction,
  locationList,
  warehouseList,
  itemList,
  handleLocationFilter,
  handleWarehouseFilter,
  handleItemFilter,
}) => {
  const balance = () => {
    const total_in_gross_weight =
      transaction?.total_in?.total_gross_weight ?? 0;
    const total_out_gross_weight =
      transaction?.total_out?.total_gross_weight ?? 0;
    const total_in_net_weight = transaction?.total_in?.total_net_weight ?? 0;
    const total_out_net_weight = transaction?.total_out?.total_net_weight ?? 0;
    const total_in_units = transaction?.total_in?.total_units ?? 0;
    const total_out_units = transaction?.total_out?.total_units ?? 0;

    return {
      gross_weight: decimalFormatter(
        total_in_gross_weight - total_out_gross_weight
      ),
      net_weight: decimalFormatter(total_in_net_weight - total_out_net_weight),
      units: commaFormatter(total_in_units - total_out_units),
    };
  };

  return (
    <div className=' bg-white   dark:bg-wdark-500  p-4 mt-8 rounded-3xl w-full'>
      <div className='flex items-center border-b   dark:border-wdark-300  border-gray-200 justify-between p-3'>
        <div className='flex text-[16px] xl:text-[20px] gap-2'>
          <p>Transaction Summary</p>
        </div>

        <div className='flex gap-6'>
          <TransactionDropdown
            locationList={locationList}
            warehouseList={warehouseList}
            itemList={itemList}
            handleLocationFilter={handleLocationFilter}
            handleWarehouseFilter={handleWarehouseFilter}
            handleItemFilter={handleItemFilter}
          />
        </div>
      </div>

      {/*TABLE */}

      <div className='p-3 mt-4'>
        <table className='border-collapse w-full'>
          <thead>
            <tr>
              <th className='p-2 font-bold bg-[#F2F2F2] dark:bg-wdark-400  border-[#e5e7eb] dark:border-wdark-300   table-cell'>
                Transaction
              </th>
              <th className='p-2 font-bold bg-[#F2F2F2] dark:bg-wdark-400  border-[#e5e7eb] dark:border-wdark-300   table-cell'>
                Gross Weight (KG)
              </th>
              <th className='p-2 font-bold bg-[#F2F2F2] dark:bg-wdark-400  border-[#e5e7eb] dark:border-wdark-300   table-cell'>
                Net Weight (KG)
              </th>
              <th className='p-2 font-bold bg-[#F2F2F2] dark:bg-wdark-400  border-[#e5e7eb] dark:border-wdark-300   table-cell'>
                Units
              </th>
            </tr>
          </thead>

          <tbody>
            <tr className='bg-[#FBFBFB] dark:bg-wdark-450    flex lg:table-row flex-row lg:flex-row flex-wrap lg:flex-no-wrap mb-10 lg:mb-0'>
              <td className='w-full lg:w-auto p-3  text-center border border-[#e5e7eb] dark:border-wdark-300  border-b  table-cell relative lg:static'>
                Uploaded Balance
              </td>
              <td className='w-full lg:w-auto p-3  border border-[#e5e7eb] dark:border-wdark-300  border-b  text-center block lg:table-cell relative lg:static'>
                {decimalFormatter(
                  transaction?.uploaded_balance?.total_gross_weight ?? 0
                )}
              </td>
              <td className='w-full lg:w-auto p-3  border border-[#e5e7eb] dark:border-wdark-300  border-b  text-center block lg:table-cell relative lg:static'>
                {decimalFormatter(
                  transaction?.uploaded_balance?.total_net_weight ?? 0
                )}
              </td>
              <td className='w-full lg:w-auto p-3  border border-[#e5e7eb] dark:border-wdark-300  border-b  text-center block lg:table-cell relative lg:static'>
                {transaction?.uploaded_balance?.total_units ?? 0}
              </td>
            </tr>

            <tr className='bg-[#FBFBFB] dark:bg-wdark-450    flex lg:table-row flex-row lg:flex-row flex-wrap lg:flex-no-wrap mb-10 lg:mb-0'>
              <td className='w-full lg:w-auto p-3  text-center border border-[#e5e7eb] dark:border-wdark-300   table-cell relative lg:static'>
                Goods Received
              </td>
              <td className='w-full lg:w-auto p-3  border border-[#e5e7eb] dark:border-wdark-300  text-center block lg:table-cell relative lg:static'>
                {decimalFormatter(
                  transaction?.goods_received?.total_gross_weight ?? 0
                )}
              </td>
              <td className='w-full lg:w-auto p-3  border border-[#e5e7eb] dark:border-wdark-300  text-center block lg:table-cell relative lg:static'>
                {decimalFormatter(
                  transaction?.goods_received?.total_net_weight ?? 0
                )}
              </td>
              <td className='w-full lg:w-auto p-3  border-r text-center block lg:table-cell relative lg:static border-[#e5e7eb] dark:border-wdark-300'>
                {commaFormatter(transaction?.goods_received?.total_units ?? 0)}
              </td>
            </tr>
            {/* Interwarehouse Transfers */}
            {transaction.iwh_transfers && (
              <tr className='bg-[#FBFBFB] dark:bg-wdark-450    flex lg:table-row flex-row lg:flex-row flex-wrap lg:flex-no-wrap mb-10 lg:mb-0'>
                <td className='w-full lg:w-auto p-3  text-center border border-[#e5e7eb] dark:border-wdark-300   table-cell relative lg:static'>
                  Interwarehouse Transfers
                </td>
                <td className='w-full lg:w-auto p-3  border border-[#e5e7eb] dark:border-wdark-300  text-center block lg:table-cell relative lg:static'>
                  {decimalFormatter(
                    transaction.iwh_transfers.total_gross_weight ?? 0
                  )}
                </td>
                <td className='w-full lg:w-auto p-3  border border-[#e5e7eb] dark:border-wdark-300  text-center block lg:table-cell relative lg:static'>
                  {decimalFormatter(
                    transaction.iwh_transfers.total_net_weight ?? 0
                  )}
                </td>
                <td className='w-full lg:w-auto p-3  border text-center block lg:table-cell relative lg:static'>
                  {commaFormatter(transaction.iwh_transfers.total_units ?? 0)}
                </td>
              </tr>
            )}
            {/* Received Transfers */}
            {transaction.received_transfer && (
              <tr className='bg-[#FBFBFB] dark:bg-wdark-450    flex lg:table-row flex-row lg:flex-row flex-wrap lg:flex-no-wrap mb-10 lg:mb-0'>
                <td className='w-full lg:w-auto p-3  text-center border border-[#e5e7eb] dark:border-wdark-300   table-cell relative lg:static'>
                  Received Transfers
                </td>
                <td className='w-full lg:w-auto p-3  border border-[#e5e7eb] dark:border-wdark-300  text-center block lg:table-cell relative lg:static'>
                  {decimalFormatter(
                    transaction.received_transfer.total_gross_weight ?? 0
                  )}
                </td>
                <td className='w-full lg:w-auto p-3  border border-[#e5e7eb] dark:border-wdark-300  text-center block lg:table-cell relative lg:static'>
                  {decimalFormatter(
                    transaction.received_transfer.total_net_weight ?? 0
                  )}
                </td>
                <td className='w-full lg:w-auto p-3  border text-center block lg:table-cell relative lg:static'>
                  {commaFormatter(
                    transaction.received_transfer.total_units ?? 0
                  )}
                </td>
              </tr>
            )}

            {/*TOTAL IN */}
            <tr className='bg-[#FBFBFB] dark:bg-wdark-450    flex lg:table-row flex-row lg:flex-row flex-wrap lg:flex-no-wrap mb-10 lg:mb-0'>
              <td className='w-full  bg-white    dark:bg-wdark-500  lg:w-auto p-3  text-center'>
                Total IN
              </td>
              <td className='w-full lg:w-auto p-3  border border-[#e5e7eb] dark:border-wdark-300  border-b  text-center block lg:table-cell relative lg:static'>
                {decimalFormatter(
                  transaction?.total_in?.total_gross_weight ?? 0
                )}
              </td>
              <td className='w-full lg:w-auto p-3  border border-[#e5e7eb] dark:border-wdark-300  border-b  text-center block lg:table-cell relative lg:static'>
                {decimalFormatter(transaction?.total_in?.total_net_weight ?? 0)}
              </td>
              <td className='w-full lg:w-auto p-3  border border-[#e5e7eb] dark:border-wdark-300  border-b  text-center block lg:table-cell relative lg:static'>
                {commaFormatter(transaction?.total_in?.total_units ?? 0)}
              </td>
            </tr>

            <tr className='bg-[#FBFBFB] dark:bg-wdark-450    flex lg:table-row flex-row lg:flex-row flex-wrap lg:flex-no-wrap mb-10 lg:mb-0'>
              <td className='w-full lg:w-auto p-3  text-center border border-[#e5e7eb] dark:border-wdark-300  border-b  table-cell relative lg:static'>
                Dispatches
              </td>
              <td className='w-full lg:w-auto p-3  border border-[#e5e7eb] dark:border-wdark-300  border-b  text-center block lg:table-cell relative lg:static'>
                {decimalFormatter(
                  transaction?.dispatches?.total_gross_weight ?? 0
                )}
              </td>
              <td className='w-full lg:w-auto p-3  border border-[#e5e7eb] dark:border-wdark-300  border-b  text-center block lg:table-cell relative lg:static'>
                {decimalFormatter(
                  transaction?.dispatches?.total_net_weight ?? 0
                )}
              </td>
              <td className='w-full lg:w-auto p-3  border border-[#e5e7eb] dark:border-wdark-300  border-b  text-center block lg:table-cell relative lg:static'>
                {commaFormatter(transaction?.dispatches?.total_units ?? 0)}
              </td>
            </tr>

            <tr className='bg-[#FBFBFB] dark:bg-wdark-450    flex lg:table-row flex-row lg:flex-row flex-wrap lg:flex-no-wrap mb-10 lg:mb-0'>
              <td className=' bg-white    dark:bg-wdark-500  w-auto p-3  text-center'>
                Total OUT
              </td>
              <td className='w-full lg:w-auto p-3  border border-[#e5e7eb] dark:border-wdark-300  border-b  text-center block lg:table-cell relative lg:static'>
                {decimalFormatter(
                  transaction?.total_out?.total_gross_weight ?? 0
                )}
              </td>
              <td className='w-full lg:w-auto p-3  border border-[#e5e7eb] dark:border-wdark-300  border-b  text-center block lg:table-cell relative lg:static'>
                {decimalFormatter(
                  transaction?.total_out?.total_net_weight ?? 0
                )}
              </td>
              <td className='w-full lg:w-auto p-3  border border-[#e5e7eb] dark:border-wdark-300  border-b  text-center block lg:table-cell relative lg:static'>
                {commaFormatter(transaction?.total_out?.total_units ?? 0)}
              </td>
            </tr>

            <tr className='bg-[#FBFBFB] dark:bg-wdark-450    flex lg:table-row flex-row lg:flex-row flex-wrap lg:flex-no-wrap mb-10 lg:mb-0'>
              <td className=' bg-white    dark:bg-wdark-500  w-auto p-3  font-bold dark:text- text-center'>
                Total Balance
              </td>
              <td className='w-full lg:w-auto p-3  border border-[#e5e7eb] dark:border-wdark-300  border-b  text-center block lg:table-cell relative lg:static font-bold'>
                {balance().gross_weight}
              </td>
              <td className='w-full lg:w-auto p-3  border border-[#e5e7eb] dark:border-wdark-300  border-b  text-center block lg:table-cell relative lg:static font-bold'>
                {balance().net_weight}
              </td>
              <td className='w-full lg:w-auto p-3  border border-[#e5e7eb] dark:border-wdark-300  border-b  text-center block lg:table-cell relative lg:static font-bold'>
                {balance().units}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TrasactionSummary;

