import React, { useEffect } from 'react';
import { useNavigate, Outlet } from 'react-router-dom';

import Navbar from './components/Navbar';
import Sidebar from './components/Sidebar';

import {
  AuthContextProvider,
  CountriesContextProvider,
  ProductsContextProvider,
  TenantsContextProvider,
  useAuth,
  useThemeCtx,
  ThemeProvider,
} from './contexts';

import './App.css';

import { MantineProvider } from '@mantine/core';
import { NotificationsProvider } from '@mantine/notifications';

function App() {
  const navigate = useNavigate();
  const { isAuthenticated } = useAuth();
  const token = localStorage.getItem('global-admin-workbench-app-token');

  useEffect(() => {
    if (!token || !isAuthenticated) return navigate('/login');

    // eslint-disable-next-line
  }, [token, isAuthenticated]);

  return (
    <div className=' bg-white   dark:bg-wdark-500 '>
      <Navbar />

      <div className='flex h-[calc(100vh-90px)]'>
        <Sidebar />
        <Outlet />
      </div>
    </div>
  );
}

function RootElement() {
  const { theme } = useThemeCtx();
  return (
    <MantineProvider
      withNormalizeCSS
      withGlobalStyles
      theme={{ colorScheme: theme ?? 'light' }}>
      <ThemeProvider>
        <AuthContextProvider>
          <CountriesContextProvider>
            <ProductsContextProvider>
              <TenantsContextProvider>
                <NotificationsProvider position='top-right'>
                  <App />
                </NotificationsProvider>
              </TenantsContextProvider>
            </ProductsContextProvider>
          </CountriesContextProvider>
        </AuthContextProvider>
      </ThemeProvider>
    </MantineProvider>
  );
}

export default RootElement;

