import React, { createContext, useContext, useEffect, useState } from 'react';

const ThemeContext = createContext({});

const ThemeProvider = ({ children }) => {
  const isStored = localStorage.getItem('global-admin-theme');
  const [enabled, setEnabled] = useState(true);
  const [themeMode, setThemeMode] = useState();
  const setTheme = (theme) => {
    setThemeMode(theme);
    if (theme === 'dark') {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
    }
    localStorage.setItem('global-admin-theme', theme);
  };

  useEffect(() => {
    if (isStored) {
      setEnabled(isStored === 'dark' ? false : true);
    } else {
      if (window.matchMedia('(prefers-color-scheme: dark)')) {
        setTheme('dark');
      } else {
        setTheme('light');
      }
    } // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (enabled) {
      setTheme('light');
    } else {
      setTheme('dark');
    }
  }, [enabled]);

  return (
    <ThemeContext.Provider
      value={{ theme: themeMode, setTheme, enabled, setEnabled }}>
      {children}
    </ThemeContext.Provider>
  );
};

const useThemeCtx = () => useContext(ThemeContext);

export { ThemeProvider, useThemeCtx };

