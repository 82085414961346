import axios from './axios';

/**
 *
 * @param {string} api_url
 * @param {number} pageNo
 * @returns
 */
export async function fetchPaginated(api_url, pageNo = 1) {
  const result = (await axios.get(`${api_url}?page=${pageNo}`)).data.data;

  return result;
}

/**
 *
 * @param {string} api_url
 * @param {number} pageNo
 * @returns
 */
export async function fetchAll(api_url, pageNo = 1) {
  const results = await fetchPaginated(api_url, pageNo);

  if (results.length > 0) {
    return results.concat(await fetchPaginated(api_url, pageNo + 1));
  } else {
    return results;
  }
}
