import React from 'react';
import Highmaps from 'highcharts/highmaps';
import {
  HighchartsMapChart,
  HighmapsProvider,
  Title,
  Tooltip,
  MapSeries,
  Credits,
  ColorAxis,
  Legend,
} from 'react-jsx-highmaps';

import { useCountriesCtx } from '../../../../contexts';

const AfricaMap = ({ data }) => {
  const { mapData } = useCountriesCtx();

  return (
    <HighmapsProvider Highcharts={Highmaps}>
      <HighchartsMapChart map={mapData}>
        <Title></Title>

        <MapSeries
          name='Commodities'
          states={{
            hover: {
              color: '#e2f8ee',
            },
          }}
          data={data}
          dataLabels={{
            enabled: false,
            color: '#FFFFFF',
            format: '{point.name}',
          }}
        />
        <ColorAxis stops={[[0, '#1D925D']]} />

        <Legend enabled={false} />
        <Tooltip />

        <Credits />
      </HighchartsMapChart>
    </HighmapsProvider>
  );
};

export default AfricaMap;

