import React, { useState } from 'react';
import { Popover } from '@mantine/core';
import { ProfileCircle, Setting2, LogoutCurve } from 'iconsax-react';
// import { useNavigate } from 'react-router-dom';

import { useAuth } from '../contexts';
import afexlogo from '../Assets/afex-logo.png';

const LogoDropdown = () => {
  // const navigate = useNavigate();
  const { signout } = useAuth();

  const [opened, setOpened] = useState(false);
  const close = () => setOpened(false);
  return (
    <Popover
      opened={opened}
      onClose={close}
      width={200}
      styles={{
        dropdown: {
          right: '4% !important',
          left: 'unset !important',
          padding: '0rem !important',
          border: 'none',
        },
      }}>
      <Popover.Target>
        <button onClick={() => setOpened((s) => !s)}>
          <img src={afexlogo} alt='Afex commodities exchange ltd' />
        </button>
      </Popover.Target>
      <Popover.Dropdown className='!bg-transparent'>
        <ul className='p-2 child:p-3 child:w-full border border-afexgreen dark:bg-wdark-500 dark:border-wdark-400 rounded-xl child:m-1 hover:child:bg-afexgreen-lighter child:rounded-xl child:transition child:duration-300 hover:child:font-semibold '>
          <button className='flex items-center gap-4' onClick={close}>
            <ProfileCircle variant='Bulk' />
            Profile
          </button>
          <button
            className='flex items-center gap-4 hover:font-semibold '
            onClick={() => {
              // navigate('settings');
              close();
            }}>
            <Setting2 variant='Bulk' />
            Settings
          </button>
          <button
            className='flex items-center gap-4'
            onClick={() => {
              close();
              signout();
            }}>
            <LogoutCurve variant='Bulk' />
            Logout
          </button>
        </ul>
      </Popover.Dropdown>
    </Popover>
  );
};

export default LogoDropdown;

