export const decimalFormatter = (value) => {
  return Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(Number(value));
};

export const commaFormatter = (value) => {
  return Intl.NumberFormat('en-US').format(Number(value));
};

export const capitalizeWords = (word) => {
  return word
    .split(/[\s_-]/)
    .map((element) => {
      return element.charAt(0).toUpperCase() + element.slice(1);
    })
    .join(' ');
};

export const formDate = (value) => {
  return new Date(value).toLocaleDateString('en-US', {
    day: '2-digit',
    month: 'short',
    year: 'numeric',
  });
};

export const formTime = (value) => {
  return new Date(value).toLocaleTimeString('en-US', {
    hour: '2-digit',
    minute: '2-digit',
  });
};
