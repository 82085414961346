import React, { useState } from 'react';
import { Menu } from '@mantine/core';
import { MdKeyboardArrowDown } from 'react-icons/md';

import Navigation from '../components/navigation';
import { useCountriesCtx } from '../../../contexts';

import CountryTile from '../components/tile';

import AfricaMap from './map';

import './map.css';

function HeatMap() {
  const { countries, singleCommodity, listItem, handleCommodityFilter } =
    useCountriesCtx();

  const africanFarmers = countries
    ?.map((el) => ({
      cont: el.country_code_2.toLowerCase(),
      farmers: el.no_of_farmers,
    }))
    ?.map((el) => {
      return el.farmers > 0 ? [el.cont, el.farmers] : [];
    });

  const commodityVolume = singleCommodity
    ?.map((el) => ({
      cont: el.country_code_2.toLowerCase(),
      volume: el?.volume || 0,
    }))
    ?.map((el) => [el.cont, el.volume]);

  const africanTenants = countries
    ?.map((el) => ({
      cont: el.country_code_2.toLowerCase(),
      tenants: el.no_of_tenants,
    }))
    ?.map((el) => [el.cont, el.tenants]);

  const [showName, setShowName] = useState('Farmers');
  const [data, setMapData] = useState(africanFarmers);

  return (
    <div className='w-[82%] flex flex-col gap-10 font-muli h-[calc(100vh-80px)]  xl:h-[calc(100vh-90px)]  bg-white   dark:bg-wdark-500 overflow-y-auto'>
      {/*CARDS */}
      <CountryTile />

      <div className='bg-bggrey dark:bg-wdark-400  flex flex-col h-[calc(100%-3%)] gap-8 p-6 rounded-3xl'>
        <Navigation />

        <div className='w-full h-[calc(100%-10%)] rounded-2xl p-6  bg-bggrey dark:bg-wdark-400 '>
          <div className='relative  bg-white   dark:bg-wdark-500 flex justify-around p-5 h-[98%] overflow-y-auto rounded-3xl'>
            <div></div>
            <AfricaMap data={data} />
            <div className='flex flex-col p-2'>
              <span className='p-3 text-sm mb-2 mt-4'>Show by:</span>
              <Menu
                position=''
                width={250}
                style={{
                  border: '6px',
                  padding: '15px',
                  width: '180px',
                  borderRadius: '15px',
                }}>
                <Menu.Target>
                  <button className='flex justify-between w-full  text-[14px] text-nowrap py-0 bg-gray-50 dark:bg-wdark-400  rounded-lg'>
                    {showName} <MdKeyboardArrowDown className='text-[20px]' />{' '}
                  </button>
                </Menu.Target>
                <Menu.Dropdown
                  style={{
                    padding: '15px',
                    borderColor: '#38CB89',
                  }}
                  className='dark:bg-wdark-500'>
                  <Menu.Label
                    className='text-[14px] hover:bg-[#e2f8ee] dark:hover:bg-afexgreen-lighter rounded-xl'
                    onClick={() => {
                      setShowName('Farmers');
                      setMapData(africanFarmers);
                    }}>
                    <span className='text-[14px]'>Farmers</span>
                  </Menu.Label>

                  <Menu.Label
                    className='text-[14px] hover:bg-[#e2f8ee] dark:hover:bg-afexgreen-lighter rounded-xl'
                    onClick={() => {
                      setShowName('Tenants');
                      setMapData(africanTenants);
                    }}>
                    Tenants
                  </Menu.Label>

                  <Menu.Label className='text-[14px] hover:bg-[#e2f8ee] dark:hover:bg-afexgreen-lighter rounded-xl'>
                    <Menu position='left-start' width={200}>
                      <Menu.Target>
                        <button
                          className='text-[14px]'
                          onClick={() => {
                            setShowName('Commodities');
                          }}>
                          Commodities
                        </button>
                      </Menu.Target>

                      <Menu.Dropdown
                        style={{
                          padding: '15px',
                          borderColor: '#38CB89',
                          height: '200px',
                          overflowY: 'auto',
                          borderRadius: '10px',
                        }}
                        className='bg-bggrey dark:bg-wdark-400  '>
                        {listItem?.map((el, index) => {
                          return (
                            <Menu.Label
                              className='text-[14px] hover:bg-[#e2f8ee] dark:hover:bg-afexgreen-lighter rounded-xl'
                              key={index}
                              onClick={() => {
                                setShowName(`Commodity: ${el.name}`);
                                handleCommodityFilter(el.code);
                                setMapData(commodityVolume);
                              }}>
                              <span className='text-[14px]'>{el.name}</span>
                            </Menu.Label>
                          );
                        })}
                      </Menu.Dropdown>
                    </Menu>
                  </Menu.Label>
                </Menu.Dropdown>
              </Menu>

              <div className='rounded-2xl p-8  mt-[200px] bg-bggrey dark:bg-wdark-400  '>
                <div className=' bg-white dark:bg-wdark-500   py-1 px-6 rounded-2xl w-full'>
                  <div className='flex text-[14px] items-center gap-5 p-3'>
                    <div
                      className='h-[20px] w-[20px]'
                      style={{ backgroundColor: '#72B746' }}></div>
                    <p className='whitespace-nowrap'>Above 50,000</p>
                  </div>
                  <div className='flex text-[14px] items-center  gap-5 p-3'>
                    <div
                      className=' h-[20px] w-[20px] '
                      style={{ backgroundColor: '#38CB89' }}></div>
                    <p className='whitespace-nowrap'>Above 40,000</p>
                  </div>
                  <div className='flex text-[14px] items-center gap-5 p-3'>
                    <div
                      className=' h-[20px] w-[20px]'
                      style={{ backgroundColor: '#1D925D' }}></div>
                    <p className='whitespace-nowrap'>Above 30,000</p>
                  </div>
                  <div className='flex text-[14px] items-center gap-5 p-3'>
                    <div
                      className=' h-[20px] w-[20px]'
                      style={{ backgroundColor: '#92D669' }}></div>
                    <p className='whitespace-nowrap'>Above 20,000</p>
                  </div>
                  <div className='flex text-[14px] items-center gap-5 p-3'>
                    <div
                      className=' h-[20px] w-[20px]'
                      style={{ backgroundColor: '#496156' }}></div>
                    <p className='whitespace-nowrap'>Above 10,000</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HeatMap;

