import React, { useState, useEffect } from 'react';
import { Modal, Skeleton } from '@mantine/core';
import { GoogleMap, useJsApiLoader, Polygon } from '@react-google-maps/api';
import { ArrowDown2, Refresh, LocationTick } from 'iconsax-react';

import ModifyLocation from './modifyLocation';

const MapModal = ({
  id,
  defaultModalData,
  lgas,
  settingModal,
  opened,
  setOpened,
  modalData,
  setModalData,
  coordinates,
  lgaOptions,
  isRefreshing,
  setisrefreshing,
  mapLoaded,
  setMapLoaded,
}) => {
  const [currentlyDisplayed, setCurrentlyDisplayed] = useState(null);
  const [openPopOver, setOpenPopover] = useState(false);
  const { title, position, data, state_id } = modalData;

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
  });

  const arrowClicked = (id) => {
    if (id === currentlyDisplayed) return true;
    else return false;
  };

  useEffect(() => {
    if (!opened) {
      setCurrentlyDisplayed(null);
      setTimeout(() => {
        setModalData(defaultModalData);
        setMapLoaded(false);
      }, 300);
    }
    // eslint-disable-next-line
  }, [opened]);

  return (
    <>
      <Modal
        opened={opened}
        onClose={() => {
          setOpened(false);
          setOpenPopover(null);
        }}
        title={`${title ? title : ''}`}
        styles={{
          modal: {
            width: '100% ',
            maxWidth: '1300px',
          },
        }}>
        <div className=' border-t border-gray-200 dark:border-wdark-300 mt-6 py-6 flex w-full h-[75vh] px-6 '>
          {title ? (
            <>
              <div className='w-[45%] maps relative rounded-[1.75rem] overflow-hidden'>
                {position && coordinates ? (
                  isLoaded ? (
                    <>
                      <GoogleMap
                        mapContainerStyle={{
                          width: '100%',
                          height: '100%',
                          fillOpacity: 0.8,
                        }}
                        center={{
                          lat: parseFloat(
                            coordinates[coordinates.length - 1].lat
                          ),
                          lng: parseFloat(
                            coordinates[coordinates.length - 1].lng
                          ),
                        }}
                        onLoad={(map) => {
                          map.setZoom(10.5);
                        }}
                        onUnmount={(map) => {}}
                        options={{
                          streetViewControl: false,
                          mapTypeControl: false,
                          fullscreenControl: false,
                        }}>
                        {mapLoaded && (
                          <Polygon
                            path={coordinates}
                            key={1}
                            options={{
                              strokeColor: '#d24e01',
                              strokeOpacity: 0.8,
                              strokeWeight: 1,
                              fillColor: '00ffffff',
                              fillOpacity: 0,
                              clickable: false,
                              draggable: false,
                              editable: false,
                              geodesic: false,
                              zIndex: 1,
                              // filter: "grayscale(0%)",
                            }}
                          />
                        )}
                        <></>
                      </GoogleMap>
                      <div className='z-20  bg-white    dark:bg-wdark-500  absolute top-4 left-4 rounded-3xl py-6 px-10 flex'>
                        <LocationTick
                          size='24'
                          color='#38cb89'
                          variant='Bulk'
                        />
                        <div className='pl-2'>
                          <p className=''> Boundary Coordinates </p>

                          <div className='flex pt-6'>
                            <div className=' pr-14 border-r border-r-gray-200'>
                              <p>
                                {position.lng}&nbsp;&#xb0;
                                {position.lng < 0 ? 'S' : 'N'}
                              </p>
                              <p className='text-xs text-afexgreen pt-2'>
                                Longitude
                              </p>
                            </div>

                            <div className=' pl-14'>
                              <p>
                                {position.lat}&nbsp;&#xb0;
                                {position.lat < 0 ? 'W' : 'E'}
                              </p>
                              <p className='text-xs text-afexgreen pt-2'>
                                Latitude
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <></>
                  )
                ) : (
                  <div className='h-full bg-gray-400 text-white flex justify-center items-center text-lg'>
                    Map Date Unavailable for this region
                  </div>
                )}
              </div>
              <div className='w-[55%] h-full pl-8 relative '>
                <div className='text-end py-1  z-[200]'>
                  <ModifyLocation
                    {...{
                      lgaOptions,
                      id,
                      settingModal,
                      state: state_id,
                      open: openPopOver,
                      setOpen: setOpenPopover,
                      index: -1,
                    }}
                  />
                </div>
                <div className=' h-full '>
                  <div className='overflow-y-scroll mt-6 h-[90%] '>
                    <table className=' table w-full'>
                      <thead className='sticky top-0 z-50'>
                        <tr className='table-head bg-bggrey dark:bg-wdark-400  p-6'>
                          <th></th>
                          <th>ID</th>
                          <th>Exchange Location</th>
                          <th>LGAs</th>
                          <th>Wards</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody className='transition-all duration-600'>
                        {data.map((item, oldIndex) => {
                          return (
                            <>
                              <tr
                                key={`main${oldIndex}`}
                                className={`child-hover:cursor-pointer  `}>
                                <td className='w-8'>
                                  <button
                                    className={`w-7 h-7 rounded-full flex items-center justify-center transition-all duration-300 dark:bg-wdark-400 dark:child:!text-white ${
                                      arrowClicked(oldIndex)
                                        ? '!bg-afexgreen rotate-180 child:text-white'
                                        : 'bg-bggrey rotate-0 child:text-[#54565b]'
                                    }`}
                                    onClick={() =>
                                      setCurrentlyDisplayed((s) =>
                                        s === oldIndex ? null : oldIndex
                                      )
                                    }>
                                    <ArrowDown2 size='16' variant='Outline' />
                                  </button>
                                </td>
                                <td>{oldIndex + 1} </td>
                                {Object.values(item).map((entry, index) => {
                                  if (
                                    index !== 4 &&
                                    index !== 5 &&
                                    index !== 6
                                  ) {
                                    if (index === 3) {
                                      const itemlgas = item.extras.data.map(
                                        (item) => item.lga
                                      );
                                      const itemlgasPks = lgas[state_id]
                                        ? lgas[state_id]
                                            .filter((item) =>
                                              itemlgas.includes(item.name)
                                            )
                                            .map((item) => item.pk)
                                        : [];
                                      return (
                                        <td
                                          key={index}
                                          className={`relative text-ellipsis max-w-[100px] text-cyan-400 td-popover  `}>
                                          <ModifyLocation
                                            {...{
                                              lgaOptions,
                                              update: true,
                                              title: entry.toString(),
                                              pk: item.pk,
                                              id,
                                              settingModal,
                                              name: item.exchange_location,
                                              lgas: itemlgasPks,
                                              state: state_id,
                                              code: item.code,
                                              open: openPopOver,
                                              setOpen: setOpenPopover,
                                              index: oldIndex,
                                            }}
                                          />
                                        </td>
                                      );
                                    } else {
                                      return (
                                        <td
                                          key={index}
                                          className={` text-ellipsis overflow-hidden max-w-[100px]`}
                                          onClick={() =>
                                            setCurrentlyDisplayed((s) =>
                                              s === oldIndex ? null : oldIndex
                                            )
                                          }>
                                          {entry.toString()}
                                        </td>
                                      );
                                    }
                                  } else return <></>;
                                })}
                              </tr>
                              {/* EXPAND */}
                              <tr key={`exp${oldIndex}`}>
                                <td
                                  colSpan={6}
                                  className={`${
                                    arrowClicked(oldIndex)
                                      ? ' px-[14px] py-[18px]'
                                      : '!p-0 '
                                  }`}>
                                  <div
                                    className={`flex justify-end transition-all duration-400 overflow-hidden ${
                                      arrowClicked(oldIndex)
                                        ? 'opacity-100 h-auto'
                                        : 'opacity-0 h-0 '
                                    }`}>
                                    <div className=' min-w-[400px] max-w-[70%] '>
                                      <table className={` w-full pb-3 `}>
                                        <thead className='sticky top-0 '>
                                          <tr className='table-head bg-bggrey dark:bg-wdark-400  p-6'>
                                            <td>Date Added</td>
                                            <td>LGA</td>
                                            <td>Wards</td>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {item.extras.data.map(
                                            (data, newIndex) => {
                                              return (
                                                <tr key={newIndex}>
                                                  {Object.values(data).map(
                                                    (newEntry, index) => {
                                                      return (
                                                        <td
                                                          key={index}
                                                          className={` text-ellipsis overflow-hidden max-w-[100px]`}>
                                                          {newEntry
                                                            ? newEntry.toString()
                                                            : 0}
                                                        </td>
                                                      );
                                                    }
                                                  )}
                                                </tr>
                                              );
                                            }
                                          )}
                                        </tbody>
                                      </table>
                                      <div className='text-xs border-t border-t-textgrey-lighter pt-3 flex justify-between '>
                                        <button
                                          className='flex items-center'
                                          onClick={() => {
                                            setisrefreshing(true);
                                            settingModal({ refresh: true });
                                          }}>
                                          <Refresh
                                            size='16'
                                            color='#555555'
                                            className={`${
                                              isRefreshing && 'animate-spin'
                                            }`}
                                          />
                                          <span className='pl-2'>Refresh</span>
                                        </button>
                                        <div className='text-textgrey-light '>
                                          Last updated: {item.extras.latestDate}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            </>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>{' '}
                </div>
              </div>
            </>
          ) : (
            <>
              <Skeleton
                height='100%'
                mt={6}
                width='100%'
                radius='xl'
                className='dark:before:bg-wdark-400 dark:after:bg-wdark-400'
              />
            </>
          )}
        </div>
      </Modal>
    </>
  );
};

export default MapModal;

