import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from '../utils/axios';

import { fetchAll } from '../utils/request_helpers';

export const ProductsListCtx = createContext();

const ProductsListProvider = ({ children }) => {
  const [dataLoaded, setDataLoaded] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [products, setProducts] = useState([]);
  const [cardData, setCardData] = useState({
    commodities: {
      value: 0,
      last_added: new Date(),
    },
    fees: {
      value: 0,
      last_added: new Date(),
    },
    inputs: {
      value: 0,
      last_added: new Date(),
    },
    total_products: {
      value: 0,
      certified_products: 0,
    },
  });

  const refreshContext = () => {
    setDataLoaded(false);
    setRefresh((s) => !s);
    setDataLoaded(true);
  };

  useEffect(() => {
    const fetchSummary = async () => {
      const resp = await axios.get('global-products');

      const { summary } = resp.data;

      setCardData(summary);
    };

    const fetchGlobalProducts = async () => {
      setProducts(await fetchAll('global-products', 1));
    };

    fetchSummary();
    fetchGlobalProducts();

    setDataLoaded(true);
  }, [refresh, dataLoaded]);
  return (
    <ProductsListCtx.Provider
      value={{ products, dataLoaded, cardData, refreshContext }}>
      {children}
    </ProductsListCtx.Provider>
  );
};

export const useProductsCtx = () => useContext(ProductsListCtx);
export default ProductsListProvider;
