import { createBrowserRouter } from 'react-router-dom';
import RootElement from './App';
import {
  CountriesHeatMap,
  CountriesList,
  SingleCountry,
  SingleTenant,
  Tenants,
  Login,
  Products,
} from './pages';

export const router = createBrowserRouter([
  {
    path: '/',
    errorElement: <RootElement />,
    element: <RootElement />,
    children: [
      {
        index: true,
        element: <Tenants />,
      },
      {
        path: 'login',
        element: <Login />,
      },
      {
        path: 'tenants',
        children: [
          {
            index: true,
            element: <Tenants />,
          },
          {
            path: ':id',
            element: <SingleTenant />,
          },
        ],
      },
      {
        path: 'countries',
        children: [
          {
            index: true,
            element: <CountriesHeatMap />,
          },
          {
            path: 'list',
            element: <CountriesList />,
          },
          {
            path: ':id',
            element: <SingleCountry />,
          },
        ],
      },
      {
        path: 'products',
        element: <Products />,
      },
    ],
  },
]);
