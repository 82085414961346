import axios from 'axios';

const base_url = process.env.REACT_APP_BASE_API_URL;
// const client_secret = process.env.REACT_APP_CF_Access_Client_Secret;
// const client_id = process.env.REACT_APP_CF_Access_Client_Id;

export const base_headers = {
  'Content-Type': 'application/json',
};
const gen_auth_header = () => {
  const token = localStorage.getItem('global-admin-workbench-app-token');

  if (!token) return base_headers;

  return {
    Authorization: `WB3 ${token}`,
    ...base_headers,
  };
};

const request = axios.create({
  baseURL: base_url,
  headers: {
    ...gen_auth_header(),
  },
});

export default request;

