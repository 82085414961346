import { Popover } from '@mantine/core';
import React from 'react';
import { BsCheck } from 'react-icons/bs';
import { IoCloseSharp } from 'react-icons/io5';
import { Formik, Form } from 'formik';
import { ArrowDown2 } from 'iconsax-react';
import * as Yup from 'yup';

import TextInput from '../../../../../../../components/TextInput';

import request from '../../../../../../../utils/axios';
import customNotification from '../../../../../../../utils/notification';

export default function ModifyLocation({
  lgaOptions,
  update,
  title,
  pk,
  id,
  settingModal,
  state,
  code,
  name,
  lgas,
  open,
  setOpen,
  index,
}) {
  // const [open, setOpen] = useState(false);

  const addingLocation = (values) => {
    request({
      method: 'post',
      url: `add/location/${id}`,
      data: { ...values, state },
    })
      .then((response) => {
        if (response.data.responseCode === '100') {
          settingModal({ refresh: true });
          customNotification({
            heading: 'Success!',
            text: 'Location added successfully.',
            id: 'success',
          });
        }
      })
      .catch((e) => {
        customNotification({
          heading: 'Error!',
          text: 'Add Location unsuccessful.',
          id: 'error',
        });
      });
  };
  const updatingLocation = (values) => {
    request({
      method: 'put',
      url: `update/location/${id}/${pk}`,
      data: { ...values, state, code },
    })
      .then((response) => {
        if (response.data.responseCode === '100') {
          settingModal({ refresh: true });
          customNotification({
            heading: 'Success!',
            text: 'Location added successfully.',
            id: 'success',
          });
        }
      })
      .catch((e) => {
        customNotification({
          heading: 'Error!',
          text: 'Add Location unsuccessful.',
          id: 'error',
        });
      });
  };
  return (
    <div className='relative '>
      <button
        type='button'
        className=' hover:cursor-pointer'
        onClick={() => {
          setOpen((s) => (s === index ? null : index));
        }}>
        {update ? (
          <span className='capitalize underline text-cyan-400 '>Update</span>
        ) : (
          <span className='px-6 py-2 bg-afexgreen text-white rounded-md text-base'>
            Add Location
          </span>
        )}
      </button>
      {open === index && (
        <div className='whitespace-normal text-textgrey dark:text-wdark-50 text-start w-[350px] shadow-md absolute top-[150%] right-[-10%] bg-white dark:bg-wdark-500 border border-wdark-450 p-4 rounded-xl z-[320]'>
          <div className='flex justify-between items-center border-b  border-textgrey-lighter p-2 py-4'>
            <span>{title ?? 'Add Location '}</span>
            <button
              className='w-5 dark:text-wdark-50'
              onClick={() => {
                setOpen(null);
              }}>
              <IoCloseSharp />
            </button>
          </div>
          <Formik
            initialValues={{ name: name ?? '', lgas: lgas ?? [] }}
            validationSchema={Yup.object({
              name: Yup.string().required('Please enter an location name'),
            })}
            onSubmit={(vals) => {
              update ? updatingLocation(vals) : addingLocation(vals);
            }}>
            {({ values, setFieldValue }) => (
              <Form className='py-4 grid gap-6'>
                <TextInput
                  name='name'
                  label='Location name'
                  id='Location name'
                  placeholder='Add location name'
                  type='text'
                  required
                />

                <Popover width={310} position='bottom'>
                  <Popover.Target>
                    <div>
                      <p className='px-2 pb-3 block'>Select LGA</p>
                      <div
                        className={`w-full bg-gray-100 dark:bg-wdark-400  py-3 px-2 rounded-xl  outline-none border border-textgrey-lighter focus:border-afexgreen-light flex justify-between items-center`}>
                        <span>
                          {values.lgas.length === 0
                            ? 'Select'
                            : `${values.lgas.length} selected`}
                        </span>
                        <ArrowDown2 size='18' color='#c9c8c6' />
                      </div>
                    </div>
                  </Popover.Target>
                  <div className=' child:capitalize relative'>
                    <Popover.Dropdown
                      style={{ width: '-webkit-fill-available' }}
                      className={` overflow-y-auto max-h-[330px] w-max transition-all duration-400 mt-1 bg-gray-100 dark:bg-wdark-400  py-5 px-2 rounded-2xl  outline-none border border-afexgreen-light   text-textgrey-light `}>
                      {lgaOptions ? (
                        <ul className='child-hover:bg-afexgreen-lighter child:pl-6 child-hover:cursor-pointer child:text-textgrey child:dark:text-wdark-50 '>
                          {lgaOptions.map((item, index) => {
                            return (
                              <li
                                key={index}
                                className='py-2 child-hover:border-afexgreen relative'>
                                <span
                                  className={` inline-flex items-center justify-center w-5 h-5  border border-white rounded-md ${
                                    values.lgas.includes(item.pk)
                                      ? 'bg-afexgreen border-afexgreen'
                                      : ' bg-white    dark:bg-wdark-500 '
                                  }`}>
                                  <BsCheck />
                                  <input
                                    type='checkbox'
                                    id={'lga-' + index}
                                    name={item.name}
                                    className='opacity-0 absolute inset-0 hover:cursor-pointer'
                                    onChange={(e) => {
                                      const checked = e.target.checked;
                                      setFieldValue(
                                        'lgas',
                                        checked
                                          ? [...values.lgas, item.pk]
                                          : values.lgas.filter(
                                              (entry) => entry !== item.pk
                                            )
                                      );
                                    }}
                                  />
                                </span>

                                <label
                                  htmlFor={'lga-' + index}
                                  className='inline-block pl-2'>
                                  {item.name}
                                </label>
                              </li>
                            );
                          })}
                        </ul>
                      ) : (
                        <div className=''>No lga data</div>
                      )}
                    </Popover.Dropdown>
                  </div>
                </Popover>

                <button
                  type='submit'
                  className='bg-afexgreen w-full py-5 text-center rounded-xl text-white mb-3 mt-6'>
                  {update ? 'Update' : 'Add'}
                </button>
              </Form>
            )}
          </Formik>
        </div>
      )}
    </div>
  );
}

