import React from 'react';
import { Modal } from '@mantine/core';

import deactivateIcon from '../../../../Assets/shocked.svg';
import axios from '../../../../utils/axios';

import notification from '../../../../utils/notification';
import { useTenantsCtx } from '../../../../contexts';

function ChangeStatus({ setViewActivate, modalData, activate }) {
  const { refreshContext } = useTenantsCtx();
  const changeStatus = async () => {
    const resp = await axios.get(`tenant/change/status/${modalData.id}`);

    if (!resp.data || resp.data.responseCode !== '100') {
      return notification({
        id: 'error',
        heading: 'Oops! Something went wrong',
        text: '',
      });
    }

    notification({
      id: 'success',
      heading: `Tenant ${
        modalData.is_active ? 'deactivated' : 'activated'
      } successfully`,
      text: '',
    });
    refreshContext();
  };

  return (
    <Modal
      centered
      onClose={() => setViewActivate(false)}
      opened={activate}
      withCloseButton={false}
      size={'500px'}>
      <div className='flex flex-col gap-5 mb-6 mt-14 items-center p-6 px-10 text-center'>
        <img
          src={deactivateIcon}
          alt='black lady with hand on mouth'
          className='h-[150px]'
        />
        <p>
          Are you sure you want to{' '}
          {modalData.is_active ? 'deactivate' : 'activate'} this account?
        </p>
        <span className='text-[#9FA19C] text-[14px]'></span>

        <div className='flex mx-auto w-full  gap-4 items-center '>
          <button
            className='bg-bggrey dark:bg-wdark-400 w-full flex py-4 whitespace-nowrap justify-center cursor-pointer gap-2  rounded-lg items-center text-[14px] hover:shadow-md ring-1 ring-gray-300 dark:ring-0 dark:hover:ring-1 dark:hover:ring-wdark-300'
            onClick={() => {
              setViewActivate(false);
            }}>
            Cancel
          </button>
          <button
            className={`w-full flex whitespace-nowrap py-4 justify-center cursor-pointer gap-2  rounded-lg items-center text-[14px] text-white ${
              modalData.is_active ? 'bg-[#e55851]' : 'bg-[#38CB89]'
            } hover:shadow-md`}
            onClick={() => {
              changeStatus();
              setViewActivate(false);
            }}>
            {modalData.is_active ? 'De-activate' : 'Activate'}
          </button>
        </div>
      </div>
    </Modal>
  );
}

export default ChangeStatus;

